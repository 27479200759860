import React from 'react'
import "./image.scss"

export default function Image({ src, alt, className = "", onClick }) {

    return (
        <div className={'Image ' + className} onClick={onClick}>
            <img src={src} alt={alt} />
        </div>
    )
}
