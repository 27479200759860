import React from 'react'
import Container from '../../components/container'
import Heading from '../../components/heading'
import "./header.scss"
import Image from "../image"

const HomeContentFunc = () => {
    return (
        <React.Fragment>
            <div className="header__content-image">
                <Image src={process.env.PUBLIC_URL + "/images/icons/drone-flying-icon.svg"} />
            </div>
            <div className="header__content-advantage">
                <div className="advantage__wrap">
                    <div className="advantage__title">50 000 Га</div>
                    <div className="advantage__text">ежегодной обработки</div>
                </div>
                <div className="advantage__wrap">
                    <div className="advantage__title">30%</div>
                    <div className="advantage__text">экономии средств</div>
                </div>
                <div className="advantage__wrap">
                    <div className="advantage__title">10 команд</div>
                    <div className="advantage__text">специалистов</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default function Header({ bg = (process.env.PUBLIC_URL + "/images/header/background-1.jpeg"), title, children = HomeContentFunc(), classes = "" }) {

    return (
        <React.Fragment>
            <header className={`section-header header--md ${classes}`} style={{ backgroundImage: `url(${bg})`, }} >
                <div className="header-wrapper">
                    <Container>
                        <div className="header__content">
                            <Heading heading={"h1"} classes="header__title" >
                                {
                                    title
                                }
                            </Heading>
                            {
                                children
                            }
                        </div>
                    </Container>
                </div>
            </header>
            <header className={`section-header header--sm ${classes}`} style={{ backgroundImage: `url(${bg})`, }} >
                <div className="header-wrapper">
                    <Container>
                        <div className="header__content--sm"></div>
                    </Container>
                </div>
            </header>
            <div className={classes}>
                <Container>
                    <div className="header__content--sm__content">
                        <Heading heading={"h1"} classes="header__title" >
                            {
                                title
                            }
                        </Heading>
                        {
                            children
                        }
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}