import React from 'react'
import "./menu.scss"
import { Link } from 'react-router-dom'
import Image from "../../image/"
import Url from '../../link'

export default function NavbarMenu(props) {
  const { classes } = props

  const navItems = [
    {
      id: 1,
      icon: "home-icon.svg",
      text: "Главная",
      link: "/"
    },
    {
      id: 2,
      icon: "insects-icon.svg",
      text: "Энтомофаги",
      link: "/entofomagi/"
    },
    {
      id: 3,
      icon: "drone-icon.svg",
      text: "Опрыскивание",
      link: "/spraying/"
    },
    {
      id: 4,
      icon: "map-icon.svg",
      text: "Картография и мониторинг",
      link: "/cartography-and-monitoring/"
    },
    {
      id: 5,
      icon: "drones-icon.svg",
      text: "Каталог продукции",
      link: "/catalog/"
    },
    {
      id: 6,
      icon: "news-icon.svg",
      text: "Новости",
      link: "/news/"
    },
    {
      id: 7,
      icon: "company-icon.svg",
      text: "О компании",
      link: "/company/"
    },
    {
      id: 8,
      icon: "phone-icon.svg",
      text: "Контакты",
      link: "/contacts/"
    }
  ]
  return (
    <ul className={'NavbarMenu ' + classes}>
      {
        navItems.map((navItem) => {
          const { id, icon, text, link } = navItem
          return (
            <li className='NavbarMenu__item' key={id} >
              <Link to={link} className='NavbarMenu__link'>
                <div className="NavbarMenu__icon">
                  <img src={process.env.PUBLIC_URL + "/images/icons/navigation-icon/" + icon} alt={text} />
                </div>
                <p className="NavbarMenu__text">{text}</p>
              </Link>
            </li>
          )
        })
      }
      <li className='NavbarMenu__item NavbarMenu__social'>
        <div className="NavbarMenu__social-wrapper">
          <Url href={"https://www.whatsapp.com/"} className="NavbarMenu__social__item">
            <Image src={process.env.PUBLIC_URL + "/images/icons/whatsapp-icon.svg"} alt={"What's app icon"} />
          </Url>

          <Url href={"https://www.telegram.com/"} className="NavbarMenu__social__item">
            <Image src={process.env.PUBLIC_URL + "/images/icons/telegram-icon.svg"} alt={"Telegram icon"} />
          </Url>

          <Url href={"https://www.whatsapp.com/"} className="NavbarMenu__social__item">
            <Image src={process.env.PUBLIC_URL + "/images/icons/wechat-icon.svg"} alt={"Wechat icon"} />
          </Url>
        </div>
      </li>
    </ul>
  )
}
