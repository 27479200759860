import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from '../container'
import Image from '../image'
import "./navigation.scss"
import Url from "../link"
import Social from '../social'
import NavbarMenu from './menu/menu'
import { Context } from '../../Context'

export default function Navigation() {

    const { BusketFunc, busketStatus } = useContext(Context)
    const [stateMenu, setMenu] = useState(false)
    const SetMenuFunc = () => {
        setMenu(stateMenu => !stateMenu)
    }

    return (
        <nav className="Nav">
            <Container>
                <div className="Navigation__row">
                    <div className="Navigation__left">
                        <Link to="/" className='logo'>
                            <Image src={process.env.PUBLIC_URL + "/images/logo.png"} alt={"Logo Image"} />
                        </Link>

                        <div className="Navigation__link-wrapper Navigation__link-mobile">
                            <Image src={process.env.PUBLIC_URL + "/images/icons/phone-icon.svg"} alt={"Phone icon"} />
                            <Url href={"tel:+78612179118"} classes="Navigation__link ">
                                +7861 21791 18
                            </Url>
                        </div>
                    </div>
                    <div className="Navigation__right">
                        <div className="Navigation__social">
                            <Social href={"https://vk.com/"} classes={"Navigation__social-item"} src={
                                process.env.PUBLIC_URL + "/images/icons/vk-icon.svg"
                            } />
                            <Social href={"https://www.instagram.com/"} classes={"Navigation__social-item"} src={process.env.PUBLIC_URL + "/images/icons/instagram-icon.svg"} />
                            <Social href={"https://www.tiktok.com/"} classes={"Navigation__social-item"} src={process.env.PUBLIC_URL + "/images/icons/tik-tok-icon.svg"} />
                        </div>
                        <div className="Navigation__lang">
                            <button className="Navigation__lang-item">en</button>
                            <button className="Navigation__lang-item">ru</button>
                        </div>
                        <div className="Navigation__option">
                            <button className="Navigation__option-item" onClick={() => BusketFunc()} >
                                <Image src={busketStatus ? (
                                    process.env.PUBLIC_URL + "/images/icons/busket-active-icon.svg"
                                ) :
                                    (
                                        process.env.PUBLIC_URL + "/images/icons/basket-icon.svg"
                                    )} alt={"Basket icon"} />
                            </button>
                            <button onClick={SetMenuFunc} className="Navigation__option-item Navigation__option-menu">
                                <Image src={stateMenu ? (
                                    process.env.PUBLIC_URL + "/images/icons/close-menu.svg"
                                ) : (
                                    process.env.PUBLIC_URL + "/images/icons/menu-icon.svg"
                                )} alt={"Menu icon"} />
                                <NavbarMenu classes={stateMenu ? "NavbarMenu-block" : ""} />
                            </button>
                            <Url href={"#!"} classes={"Navigation__option-item Navigation__option-item--mobile"} text={
                                <Image src={process.env.PUBLIC_URL + "/images/icons/phone-icon-2"} alt={"Menu icon"} />
                            } />
                        </div>

                    </div>
                </div>
            </Container>
        </nav>
    )
}
