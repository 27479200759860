import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// Impport Styles
import "./cartography-monitoring.scss"
// Import Components
import Header from '../../components/header'
import Heading from '../../components/heading'
import Image from '../../components/image'
import Params from '../../components/params'
import Container from '../../components/container'
import SectionTitle from '../../components/section-title'
import Card from '../../components/card'
import Form from '../../components/form'
// Import Images
import Material from "../../images/news/3.jpeg"
import CartographyAndMonitoring from "../../images/cartography/map-monitoring-bg.jpg"
import Resolution from "../../images/icons/card/resolution-icon.svg"
import Coordinates from "../../images/icons/card/coordinates-icon.svg"
import Rubl from "../../images/icons/card/savings-icon.svg"
import Work from "../../images/cartography/work.png"
import Setting from "../../images/icons/card/setting-icon.svg"
import Time from "../../images/icons/card/time-icon.svg"
import Tool from "../../images/icons/card/tool-icon.svg"
import Mashab from "../../images/icons/mashab-icon.svg"
import Harvest from "../../images/cartography/icon/harvest-icon.svg"
import Forest from "../../images/cartography/icon/forest-icon.svg"
import Blueprint from "../../images/cartography/icon/blueprint-icon.svg"
import PowertPlant from "../../images/cartography/icon/power-plant-icon.svg"
import Building from "../../images/cartography/icon/building-icon.svg"
import Building2 from "../../images/cartography/icon/building2-icon.svg"
import Mountains from "../../images/cartography/icon/mountains-icon.svg"
import Thermometer from "../../images/cartography/icon/thermometer-icon.svg"
import Fields from "../../images/cartography/image.jpeg"
import SectionDesc from '../../components/section-desc'
import Slider from '../../components/slider'

const BenefitsArray = [
    {
        id: 0,
        img: Setting,
        text: "Полная автоматизация "
    },
    {
        id: 1,
        img: Time,
        text: "Полная автоматизация "
    },
    {
        id: 2,
        img: Tool,
        text: "Полная автоматизация "
    }
]

const FieldsArray = [
    {
        id: 0,
        icon: Harvest,
        name: "Сельское хозяйство ",
        status: true
    },
    {
        id: 1,
        icon: Forest,
        name: "Лесное хозяйство",
        status: false
    },
    {
        id: 2,
        icon: Blueprint,
        name: "Геодезия",
        status: false
    },
    {
        id: 3,
        icon: PowertPlant,
        name: "Энергетика",
        status: false
    },
    {
        id: 4,
        icon: Building,
        name: "Строительство",
        status: false
    },
    {
        id: 5,
        icon: Building2,
        name: "Градостроительство",
        status: false
    },
    {
        id: 6,
        icon: Mountains,
        name: "Градостроительство",
        status: false
    },
    {
        id: 7,
        icon: Thermometer,
        name: "Тепловизионная съемка",
        status: false
    }
]

const ContentFieldsArray = [
    {
        id: 0,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    },
    {
        id: 1,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    },
    {
        id: 2,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    },
    {
        id: 3,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    },
    {
        id: 4,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    },
    {
        id: 5,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    },
    {
        id: 6,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    },
    {
        id: 7,
        text: "Инвентаризация сельхозугодий, состояние посевов и всхожести, развития заболеваний, количества растений при рядной посадке, зарастания сорняками, состояния переувлажненных или засушливых участков полей, расчет  NDVI и других индексов. Мониторинг техники, сопровождение агротехнических мероприятий",
        image: Fields
    }
]

const AdvantageArray = [
    {
        id: 1,
        img: Rubl,
        title: "Низкая цена аэрофотосъемка",
        body: `Стоимость беспилотной аэрофотосъемка в разы меньше стоимости получения данных спутниковой съемки, а также традиционных методов приведения геодезических изысканий.`,
        type: "card"
    },
    {
        id: 2,
        img: Time,
        title: "Высокая производительность",
        body: `За день может быть выполнена съемка до нескольких сотен квадратных километров.`,
        type: "card"
    },
    {
        id: 3,
        img: Resolution,
        title: "Высокие разрешение снимков",
        body: `Беспилотники Геоскана позволяют получать снимки с пространственным разрешением до 1см/пикс, что обеспечивает высокий уровень детализации получаемых моделей и ортофотопланов.`,
        type: "card"
    },
    {
        id: 4,
        img: Coordinates,
        title: "Работа в требуемой системе координат",
        body: `Результаты полета- геопривязанные фотографии, которым в процессе обработки можно задать привязку к необходимой системе координат`,
        type: "card"
    }
]

export default function Cartography() {
    // Tabs
    const [MaterialButtonActive, setMaterialButtonActive] = useState(1)
    const [WorkTab, setWorkTab] = useState(1)
    const [UsingTab, setUsingTab] = useState(0)
    // Arrays
    const [benefits] = useState(BenefitsArray)
    const [usingField] = useState(FieldsArray)
    const [contentFields] = useState(ContentFieldsArray)
    const [advantage] = useState(AdvantageArray)

    return (
        <React.Fragment>
            <Header bg={CartographyAndMonitoring} classes={"Cartography"} title={"Агромониторинг для управления бизнесом"}>
                <Heading>
                    Высокое качество продукции и хороший урожай - результат точного планирования и быстрого реагирования на возникающие посевам угроз
                </Heading>

                <div className="Cartography__benefits">
                    {
                        benefits.map(benefit => {
                            const { id, img, text } = benefit
                            return (
                                <div className="benefit__item" key={id}>
                                    <div className="benefit__icon">
                                        <Image src={img} />
                                    </div>

                                    <p className="benefit__text">{text}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </Header>

            <Params className={'Cartography--params'} />

            <main className='inner Cartography--inner'>
                <section className="Cartography-Service">
                    <Container>
                        <SectionTitle className='Cartography-Service__titles' title={"Об Услуге"} subtitle={"Профессиональная аэрофотосъемка"} />

                        <SectionDesc className='Cartography-Service__text'>
                            Аэрофотосъемка осуществляется с применением БПЛА Геоскан
                        </SectionDesc>
                    </Container>
                </section>

                <section className='Cartography Advantage Cartography--refactoring'>
                    <Container>

                        <SectionTitle className={'Advantage__titles'} title={"Плюсы аэрофотосъемки"} subtitle={"Приемущества аэрофотосъемки"} />

                        <Slider className='Advantage__row' array={advantage} classNamePagination='Advantage--pagination'>
                            {
                                advantage.map((item) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <Card card={item.type} img={item.img} title={item.title} body={item.body} />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Slider>

                    </Container>
                </section>

                <section className='Work'>
                    <Container>
                        <SectionTitle className='Work__titles Work__titles--refactoring' title={"Как это работает"} subtitle={"Процесс аэрофотосъемки"} />

                        <div className="Work__text">
                            <p>
                                Аэрофотосъемки разделяется на этапы полевых и камеральных работ, при этом набор данных, получаемых по результатам камеральных работ, варьируется в зависимости от требований заказчика
                            </p>
                        </div>

                        <div className="Work__row Cartography--Work__row">

                            <Image src={Work} className='Work__imageholder' />

                            <div className="Work__content">
                                <div className="tabs">
                                    <div className="tabs__buttons">
                                        <button className={`tabs__button ${WorkTab === 1 ? "active" : ""}`} onClick={() => setWorkTab(1)}>Полевые работы</button>
                                        <button className={`tabs__button ${WorkTab === 2 ? "active" : ""}`} onClick={() => setWorkTab(2)} >Камеральные работы</button>
                                    </div>

                                    <ul className="tabs__content">
                                        <li className='tabs__item'>
                                            <p>
                                                Получение разрешений на аэрофотосъемку
                                            </p>
                                        </li>
                                        <li className='tabs__item'>
                                            <p>
                                                Выполнение аэрофотосъемки с использованием БПЛА
                                            </p>
                                        </li>
                                    </ul>

                                    <Link to={"/contacts"} className="tabs__link">
                                        Получить консультацию

                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>

                <section className="Material">
                    <Container>
                        <div className="">
                            <SectionTitle className='Material__titles' title={"Получаемые данные"} subtitle={"Материалы аэрофотосъемки"} />
                        </div>
                        <div className="Material__text">
                            <p>
                                Фотограмметрическая  обработка, выполняемая в Pix4D, позволяет получать необходимые данные для дальнейшего использования
                            </p>
                        </div>
                        <div className="tabs Material--tabs">
                            <div className="tabs__buttons">
                                <button className={`tabs__button ${MaterialButtonActive === 1 ? "active" : ""}`} onClick={() => setMaterialButtonActive(1)}>Ортофотоплан </button>
                                <button className={`tabs__button ${MaterialButtonActive === 2 ? "active" : ""}`} onClick={() => setMaterialButtonActive(2)}>NDVI</button>
                                <button className={`tabs__button ${MaterialButtonActive === 3 ? "active" : ""}`} onClick={() => setMaterialButtonActive(3)}>Цифровая модель местности</button>
                                <button className={`tabs__button ${MaterialButtonActive === 4 ? "active" : ""}`} onClick={() => setMaterialButtonActive(4)}>3D-модель <span className="span">местности</span></button>
                            </div>

                            <div className="tabs__row">
                                <div className="tabs__content tabs__content--refactoring">
                                    <p className='tabs__text'>
                                        Одним файлом или в виде тайлов. Форматы: geotiff, jpg, png, GoogleEarth KML\KMZ
                                    </p>

                                    <ul className='tabs__list'>
                                        <li className="tabs__item Material--item">
                                            <Image src={Resolution} />
                                            <p>
                                                Разрешение до 2см/пикс
                                            </p>
                                        </li>

                                        <li className="tabs__item Material--item">
                                            <Image src={Mashab} />
                                            <p>
                                                Масштаб от 1:500 до 1:10000
                                            </p>
                                        </li>

                                        <li className="tabs__item Material--item">
                                            <Image src={Tool} />
                                            <p>
                                                Высокая геодезическая  точность
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tabs__imageholder">
                                    <Image src={Material} />
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>

                <section className="Using">
                    <Container>
                        <SectionTitle className='Using__titles' title={"Области применения"} subtitle={"Применение аэрофотосъемки "} />

                        <div className="Using__row">

                            <ul className="Using__list">
                                {
                                    usingField.map((item) => {
                                        const { id, icon, name } = item
                                        return (
                                            <li onClick={() => setUsingTab(id)} className={`Using__item ${UsingTab === id ? "active" : ""}`} key={id}>

                                                <div className="Using__item Using__item-wrapper">
                                                    <Image src={icon} />
                                                    <p className='Using__item__text'>
                                                        {
                                                            name
                                                        }
                                                    </p>
                                                </div>

                                                <div className={`Using__item-content ${UsingTab === id ? "show" : ""}`}>
                                                    {
                                                        contentFields.map(content => {
                                                            const { text, image } = content

                                                            return UsingTab === content.id ? <React.Fragment key={id}>
                                                                <p className="Using__content__text">{text}</p>

                                                                <Image classes='Using__imageholder' src={image} alt={"error"} />
                                                            </React.Fragment> : null
                                                        })
                                                    }
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                            {
                                contentFields.map(content => {
                                    const { id, text, image } = content

                                    return UsingTab === id ? <React.Fragment>
                                        <div className={"Using__content"} key={id}>
                                            <p className="Using__content__text">
                                                {text}
                                            </p>

                                            <div className="Using__imageholder">
                                                <img src={image} alt="error" />
                                            </div>
                                        </div>
                                    </React.Fragment> : null
                                })
                            }
                        </div>
                    </Container>
                </section>
                <Form />
            </main>

        </React.Fragment>
    )
}
