import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCart } from 'react-use-cart'

import "./product.scss"
import Params from '../../components/params'
import Container from '../../components/container'
import Heading from '../../components/heading'
import Image from '../../components/image'
import Card from '../../components/card'
import { Context } from '../../Context'
import NotFound from '../notfound'

// Image
import Fuel from "../../images/icons/catalog/fuel-icon.svg"
import IP67 from "../../images/icons/catalog/IP67-icon.svg"
import RTK from "../../images/icons/catalog/RTK-icon.svg"
import Width from "../../images/icons/catalog/width-icon.svg"
import Droneone from "../../images/icons/card/droneone-icon.svg"
import Tree from "../../images/icons/card/tree-icon.svg"
import Delivery from "../../images/icons/delivery-icon.svg"
import Drone6 from "../../images/icons/drone-6-icon.svg"
import Wallet from "../../images/icons/card/wallet-icon.svg"
import Security from "../../images/icons/security-icon.svg"
import Delete from "../../images/icons/delete-icon.svg"
import SectionPic1 from "../../images/products/product-4.jpeg"
import SectionPic2 from "../../images/products/product-5.jpeg"
import SectionPic3 from "../../images/products/product-6.jpeg"
import SectionPic4 from "../../images/products/product-8.png"
import SectionPic5 from "../../images/products/product-9.png"
import SectionPic6 from "../../images/products/product-10.png"
import SectionPic7 from "../../images/products/product-11.png"
import axios from 'axios'

const AccardionList = [
  {
    id: 0,
    title: "Летательный аппарат"
  },

  {
    id: 1,
    title: "Основные характеристики"
  },

  {
    id: 2,
    title: "Функции БПЛА"
  },

  {
    id: 3,
    title: "Комплектация"
  },
  {
    id: 4,
    title: "Отдельно приобретаются"
  }
]

export default function Products() {
  let totalPrice = 0

  const { id } = useParams()

  const [Product, setProduct] = useState({})

  const { items, updateItemQuantity, addItem, removeItem, isEmpty } = useCart()

  const { NumberFormatterFunc, BusketFunc, productsArray, URLData } = useContext(Context)

  const [accardion] = useState(AccardionList)
  const [ImageActive, setImageActive] = useState(1)
  const [accardionPlus, setAccardionPlus] = useState(0)
  const [ImageChange, setImageChange] = useState("")

  const ImageActiveFunc = (id) => {
    setImageActive(id)
  }

  useEffect(() => {
    axios.get(`${URLData}/products/${id}`).then(response => {
      const data = response.data;
      setProduct(data)
      setImageChange(data.cover)
    })
  }, [id])

  if (isEmpty) {
    return <NotFound />
  }

  return (
    <React.Fragment>
      <Params className='Product--params' />

      <main className="inner Product--inner">
        <section className="Product">
          <Container>
            <div className="Product__row">
              <div className="Product__images">

                <Image src={ImageChange} alt={"error"} />

                {
                  Product.images?.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Image src={process.env.PUBLIC_URL + item} onClick={() => ImageActiveFunc(i)} className={`small ${ImageActive === i ? "active" : ""}`} />
                      </React.Fragment>
                    )
                  })
                }
              </div>
              <div className="Product__content">
                <div className="Product__titles">
                  <Heading heading={"h3"}> { } </Heading>
                  <Heading>Артикуль: 12358</Heading>
                </div>

                <ul className="Product__list">
                  <li className='Product__item'>
                    <Image className="Product__item-icon" src={Fuel} />
                    <p className='Product__item-text'>Бак для распыления 22 л</p>
                  </li>

                  <li className='Product__item'>
                    <Image className="Product__item-icon" src={Tree} />
                    <p className='Product__item-text'>Датчик высоты и обхода препятствий</p>
                  </li>

                  <li className='Product__item'>
                    <Image className="Product__item-icon" src={Width} />
                    <p className='Product__item-text'>Ширина захвата 5-8 м</p>
                  </li>

                  <li className='Product__item'>
                    <Image className="Product__item-icon" src={IP67} />
                    <p className='Product__item-text'>Воднепроницаемость</p>
                  </li>

                  <li className='Product__item'>
                    <Image className="Product__item-icon" src={RTK} />
                    <p className='Product__item-text'>Высокоточное позиционирование</p>
                  </li>
                  <li className='Product__item'>
                    <Image className="Product__item-icon" src={Droneone} />
                    <p className='Product__item-text'>Скорость полета 10 м/с</p>
                  </li>
                </ul>

                <div className="Product__charactery">
                  <Heading heading={"h5"}>Все характеристики</Heading>

                  <div className="Product__charactery-wrap">
                    <p className="price"> $
                      {
                        items.map((item) => {
                          // return item.title === title ? NumberFormatterFunc(item.price * item.quantity) : null
                        })
                      }
                    </p>

                    <div className="counter">
                      {
                        items.map((item) => {
                          // return item.title === title ? <React.Fragment key={item.id}>
                          //   <button className='counter__button button-decreament' onClick={() => updateItemQuantity(item.id, item.quantity - 1)} >-</button>
                          //   <span className="count">
                          //     {
                          //       item.quantity
                          //     }
                          //   </span>
                          //   <button className='counter__button button-increament' onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>+</button>
                          // </React.Fragment> : ""
                        })
                      }

                    </div>
                  </div>

                  <div className="Product__buttons">
                    {
                      items.map((item) => {
                        // return item.title === title ? <React.Fragment key={item.id}>
                        //   <button className='Product__button' onClick={() => addItem(item)}>Добавить в корзину</button>
                        //   <button className='Product__button button-transparent' onClick={BusketFunc}>Купить в 1 клик</button>
                        // </React.Fragment> : null
                      })
                    }
                  </div>
                </div>


              </div>
            </div>
          </Container>
        </section>

        <section className='Charactery'>
          <Container>
            <ul className="Product__charactery-list">
              <li className='Product__charactery-item'>
                <Card card={"card"} img={Delivery} title={"Бесплатная доставка"} body={"Купить кважракоптер - получить бесплатную доставку"} />
              </li>

              <li className='Product__charactery-item'>
                <Card card={"card"} img={Drone6} title={"Пробный первый полет"} body={"Краткое обучение и техника безопасности"} />
              </li>

              <li className='Product__charactery-item'>
                <Card card={"card"} img={Wallet} title={"Гарантия лучшей цены"} body={"Лучшая цена от официального производителя "} />
              </li>

              <li className='Product__charactery-item'>
                <Card card={"card"} img={Security} title={"Официальная гарантия"} body={"На всю продукцию представленную в магазине"} />
              </li>
            </ul>

            <div className="pagination Product__charactery--pagination">
              <button className="pagination__item active"></button>
              <button className="pagination__item"></button>
              <button className="pagination__item"></button>
              <button className="pagination__item"></button>
            </div>
          </Container>
        </section>

        <section className='Often-bought'>
          <Container>
            <Heading heading={"h3"}>С этим товаром часто покупают </Heading>

            <div className="Often-bought__row">
              <div className="Often-bought__card">
                <Heading>Ваш товар</Heading>
                {
                  <Card card={"product"}
                    img={Product.cover}

                    title={Product.title}

                    brand={Product.brand}

                    model={Product.model}

                    price={Product.price}

                    path={"button"}

                    button={<React.Fragment>
                      <button className='counter__button button-decreament'>-</button>
                      <span className="count">
                        {
                          1
                        }
                      </span>
                      <button className='counter__button button-increament'>+</button>
                    </React.Fragment>}
                  />
                }

              </div>

              {
                productsArray.filter(category => category.category === "often bought")
                  .map((item) => {
                    totalPrice += item.price * item.quantity

                    return (
                      <React.Fragment key={item.id}>
                        <div className="Often-bought__card bought">
                          <Image src={Delete} className='bought__delete-button' onClick={() => removeItem(item.id)} />
                          <Card card={"product"}
                            img={item.img}

                            title={item.title}

                            brand={item.brand}

                            model={item.model}

                            price={(item.price * item.quantity)}

                            path={"button"}

                            button={<React.Fragment>
                              <button className='counter__button button-decreament' onClick={() => updateItemQuantity(item.id, item.quantity - 1)} >-</button>
                              <span className="count">
                                {
                                  item.quantity
                                }
                              </span>
                              <button className='counter__button button-increament' onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>+</button>
                            </React.Fragment>}
                          />
                        </div>
                      </React.Fragment>
                    )
                  })
              }

              <div className="Often-bought__card price">
                <p className='purchase-price'>$ {NumberFormatterFunc(totalPrice)} </p>
                <button className='Product__button price__button'>Добавить в корзину</button>
                <button className='Product__button button-transparent price__button'>Собрать свой комплект</button>
              </div>
            </div>
          </Container>
        </section>

        <section className='Product__description'>
          <Container>
            <Heading heading={"h3"} className={"Product__title"}>
              {
                // title
              }
            </Heading>
            <div className="Product__text">
              <p>
                A22RTK продолжает превосходство классической конструкции сельскохозяйственного беспилотника AGR. Имеет литой корпус и цельнолитое литье, что увеличивает максимальную нагрузку дрона до 22 кг. Недавно разработанное Т-образное сопло выводит эффективность работы на новый уровень; Интеллектуальная система управления полетом AG3Pro предназначена для точного и эффективного продвижения технологической модернизации и инноваций в развитии сельскохозяйственной отрасли
              </p>
            </div>

            <Image className={"Product__imageholder"} src={SectionPic1} alt={"error"} />
          </Container>
        </section>

        <section className='Product__description Product__description--refactoring'>
          <Container>
            <Heading heading={"h3"} className={"Product__title"}>Интеллектуальная система управления полетом AG3 Pro</Heading>
            <div className="Product__text">
              <p>
                Несколько схем обновления, интеграция модулей, стабильная и надежная арифметика. <br className='Product__break' />
                Интегрируйте 20 материнских плат, основной блок управления, цифровую связь, RTK и другие модули в простой конструкции, нет необходимости в соединительных проводах <br className='Product__break' /> между предыдущими модулями, IP67, меньший вес модуля управления
              </p>
            </div>

            <Image className={"Product__imageholder"} src={SectionPic2} alt={"error"} />
          </Container>
        </section>

        <section className='Product__description Product__desc--refactoring'>
          <Container>
            <div className="Product__description-images">
              <div className="Product__image">
                <Image src={SectionPic4} />
                <Heading heading={"h4"}>Литье под давлением «все в одном»</Heading>
                <p className='Product__description-text'>Простой и последовательный, простой в обслуживании</p>
              </div>

              <div className="Product__image">
                <Image src={SectionPic5} />
                <Heading heading={"h4"}>Резурвуар для воды объемом 22 литров </Heading>
                <p className='Product__description-text'>Полностью встроенный магниточувствительный датчик уровня жидкости, <br className='Product__break' /> обратная связь в режиме реального времени об использовании пестицидов</p>
              </div>

              <div className="Product__image">
                <Image src={SectionPic6} />
                <Heading heading={"h4"}>Четырехходовой настенный бесщеточный водяной насос</Heading>
                <p className='Product__description-text'>Точно контролирует объем распыления с максимальной скоростью потока <br className='Product__break' /> 8 литров в минуту.</p>
              </div>

              <div className="Product__image">
                <Image src={SectionPic7} />
                <Heading heading={"h4"}>Эффективная ширина распыления T-типа</Heading>
                <p className='Product__description-text'>Форсунка для сброса давления может достигать 8 метров, а производительность <br className='Product__break' /> составляет 4-14 га/час .</p>
              </div>
            </div>

            <div className="pagination Product__description--pagination">
              <button className="pagination__item active"></button>
              <button className="pagination__item"></button>
              <button className="pagination__item"></button>
              <button className="pagination__item"></button>
            </div>
          </Container>
        </section>

        <section className='Product__description Product--desc'>
          <Container>
            <Heading heading={"h3"} className={"Product__title"}>Точное предотвращение препятствий, лучшая защита <br className='Product__title-break' /> для безопасности</Heading>
            <div className="Product__text">
              <p>
                A22 RTK поддерживает радар миллиметрового диапазона, моделирующий поверхность земли, и радар предотвращения препятствий для отслеживания рельефа местности и предупреждения о столкновении с препятствиями. Если вы столкнулись с препятствиями, вы можете парить или обходить препятствия, чтобы повысить безопасность полета.
              </p>
            </div>

            <Image className={"Product__imageholder"} src={SectionPic3} alt={"error"} />
          </Container>
        </section>

        <section className='Product__characteristics'>
          <Container>
            <Heading heading={"h3"} className={"Product__title"}>Характеристики</Heading>
            <div className="characteristics__row">

              <ul className='characteristics__list'>
                {
                  accardion.map((item) => {
                    return (
                      <li className='list__item' key={item.id} onClick={() => setAccardionPlus(item.id)} >
                        <Heading> {item.title} </Heading>
                        <span className='item__icon'>
                          {
                            accardionPlus === item.id ? "-" : "+"
                          }
                        </span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

          </Container>
        </section>

        <section className='Product__like'>
          <Container>
            <Heading heading={"h3"} className={"Product__title Product__title--refactoring"}>Похожие товары</Heading>
          </Container>

          <Container className='Container-left Product__slider'>


            <div className="product__cards">
              {
                productsArray.filter(category => category.category === "product").map((product) => {
                  const { img, brand, model, price, id } = product

                  // return product.title !== title ? <Card path={`/products/${product.title}`} brand={brand} model={model} price={price} title={product.title} key={id} img={img} card={"product"} /> : null
                })
              }
            </div>

          </Container>

          <Container>
            <div className="pagination Product__slider--pagination">
              <button className="pagination__item active"></button>
              <button className="pagination__item"></button>
              <button className="pagination__item"></button>
            </div>
          </Container>
        </section>
      </main>
    </React.Fragment>
  )
}
