import { createContext, useState } from "react";
import { productsArray } from "./db"

const Context = createContext()

const ContextProvider = ({ children }) => {

    const URLData = "https://agroscout-server-api.onrender.com"

    const [busketStatus, setBusketStatus] = useState(false)

    const BusketFunc = () => setBusketStatus(busketStatus => !busketStatus)

    const NumberFormatterFunc = (number = 0) => {
        const formatterNumber = new Intl.NumberFormat("ru")
        return formatterNumber.format(number)
    }

    return (
        <Context.Provider value={{ BusketFunc, busketStatus, NumberFormatterFunc, productsArray, URLData }}>
            {children}
        </Context.Provider>
    )
}

export { ContextProvider, Context }