import React, { useState } from 'react'

export default function Pagination({ className = "", array = [] }) {
    const [activeButton, setActiveButton] = useState(0)

    const ActiveButtonFunc = index => {
        setActiveButton(index)
    }

    return (
        <React.Fragment>
            <div className={`pagination ${className}`}>
                {
                    array.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <button onClick={() => ActiveButtonFunc(index)} className={`pagination__item ${activeButton === index ? "active" : ""}`}></button>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}
