import React, { useState } from 'react'
// Style
import "./company.scss"

// Components
import Header from "../../components/header"
import Heading from '../../components/heading'
import Image from "../../components/image"
import Params from "../../components/params"
import SectionTitle from '../../components/section-title'
import Card from '../../components/card'
import Container from '../../components/container'

// Images
import Background from "../../images/about/background.jpg"
import Drone from "../../images/icons/drone-flying-icon.svg"
import About from "../../images/about/about1.jpeg"
import Map from "../../images/other/map.svg"
import Form from '../../components/form'
import SectionDesc from '../../components/section-desc'
import Slider from '../../components/slider'

const advantagesArray = [
    {
        id: 0,
        title: "50 000 Га",
        text: "ежегодной обработки"
    },
    {
        id: 1,
        title: "30%",
        text: "экономии средств"
    },
    {
        id: 2,
        title: "10 команд",
        text: " специалистов"
    }
]

const ManagementArray = [
    {
        id: 1,
        img: About,
        title: "Олег Иванченко",
        subtitle: "Генеральный директор компнии",
        body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi facilisi ultrices ut consectetur nibh tincidunt. Elit nunc, volutpat, eu ac egestas suspendisse vulputate. Sed feugiat gravida pellentesque dictum nisi rhoncus montes. Duis tellus auctor eget nibh sed erat risus lorem.`,
        type: "about"
    },
    {
        id: 2,
        img: About,
        title: "Олег Иванченко",
        subtitle: "Генеральный директор компнии",
        body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi facilisi ultrices ut consectetur nibh tincidunt. Elit nunc, volutpat, eu ac egestas suspendisse vulputate. Sed feugiat gravida pellentesque dictum nisi rhoncus montes. Duis tellus auctor eget nibh sed erat risus lorem.`,
        type: "about"
    },
    {
        id: 3,
        img: About,
        title: "Олег Иванченко",
        subtitle: "Генеральный директор компнии",
        body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi facilisi ultrices ut consectetur nibh tincidunt. Elit nunc, volutpat, eu ac egestas suspendisse vulputate. Sed feugiat gravida pellentesque dictum nisi rhoncus montes. Duis tellus auctor eget nibh sed erat risus lorem.`,
        type: "about"
    }
]

export default function Company() {

    const [advantages] = useState(advantagesArray)
    const [managements] = useState(ManagementArray)

    return (
        <React.Fragment>
            <Header title={"О компании AgroScout"} bg={Background} classes={"Company"}>
                <Heading>
                    Наша команда состоит из профессионалов в области эффективного применения беспилотных летательных аппаратов
                </Heading>
                <div className="Company__drone">
                    <Image src={Drone} />
                </div>

                <div className="Company__advantage">
                    {
                        advantages.map((advantage) => {
                            const { id, title, text } = advantage
                            return (
                                <React.Fragment key={id}>
                                    <div className="advantage__item">
                                        <Heading heading={"h4"} classes={"advantage__title"}>{title}</Heading>
                                        <p className='advantage__text'> {text} </p>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </Header>

            <Params className='Company--params' />

            <main className='inner Company--inner'>
                <section className='Company__desc'>
                    <Container>

                        <SectionDesc className='Company__text'>
                            <span className="text__tablet">
                                Дроны и БПЛА (беспилотные летальные аппараты) уже сегодня являются важным и стремительно развивающимся сегментом мирового рынка. И этот сегмент находит применение в абсолютно разных сферах жизнедеятельности человека. На сегодняшний день многие коммерческие компании рассматривают дроны и БПЛА в качестве прекрасного инструмента для решения различных задач. И эта тенденция только растет. Согласно исследованию Всемирной организации беспилотных систем (the Organization for Unmanned Vehicle Systems Worldwide), только в сельском хозяйстве общая экономическая эффективность применения дронов к 2025 году составит около
                            </span>

                            <span className="text__mobile">
                                Компания STS.center применяет высокотехнологичные агродроны и БПЛА, которые модернизированы и собраны нашими инженерами для сельского и лесного хозяйства, а также для промышленности.
                            </span>
                        </SectionDesc>
                    </Container>
                </section>

                <section className="Company-about">
                    <Container>
                        <SectionTitle className='Company-about__title' title={"О нас"} subtitle={"Наша команда"} />

                        <Slider className='Company-about__row' array={managements} classNamePagination='Company-about--pagination'>
                            {
                                managements.map((item) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <Card card={item.type} img={item.img} title={item.title} subtitle={item.subtitle} body={item.body} />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Slider>

                    </Container>
                </section>

                <section className='work work--reafctoring'>

                    <Container>
                        <SectionTitle className='work__titles' title={"Где мы работаем"} subtitle={"Регионы присутствия"} />
                    </Container>
                    <Container className='Container-left'>

                        <div className="work__row">
                            <div className="work__lists">
                                <ol className="work__list">
                                    <li>1. Московская область</li>
                                    <li>2. Ростовская область</li>
                                    <li>3. Краснодарский край</li>
                                    <li>4. Ставропольский край</li>
                                    <li>5. Северная Осетия</li>
                                    <li>6. Кабардино-Балкария</li>
                                    <li>7. Пензеская область</li>
                                    <li>8. Белгородская область</li>
                                    <li>9. Саратовская область</li>
                                    <li>10. Воронежская область</li>
                                    <li>11. Волгоградская область</li>
                                    <li>12. Астраханская область</li>

                                </ol>
                                <ol className="work__list">
                                    <li>13. Самарская область</li>
                                    <li>14. Липецкая область</li>
                                    <li>15. Орловская область</li>
                                    <li>16. Курская область</li>
                                    <li>17. Ульяновская область</li>
                                    <li>18. Карачаево-Черкессия</li>
                                    <li>19. Брянская область</li>
                                    <li>20. Республика Татарстан</li>
                                    <li>21. Тамбовская область</li>
                                    <li>22. Тульская область</li>
                                    <li>23. Калужская область</li>
                                    <li>24. Республика Крым</li>
                                </ol>
                            </div>
                            <div className="work__imageholder">
                                <Image src={Map} />
                            </div>
                        </div>
                    </Container>
                </section>

                <Form className='Company--form' />
            </main>
        </React.Fragment>
    )
}
