import React, { useContext } from 'react'
import "./busket.scss"
import { useCart } from 'react-use-cart'
import Heading from './../heading'
import Close from "../../images/icons/close-menu.svg"
import Image from '../image'
import Container from '../container'
import Delete from "../../images/icons/delete-default-icon.svg"
import { Context } from '../../Context'
import { Link } from 'react-router-dom'
import BusketDronde from "./../../images/icons/busket-drone.png"

export default function Busket() {

    const { busketStatus, BusketFunc, NumberFormatterFunc } = useContext(Context)
    let totalPrice = 0

    const {
        isEmpty,
        items,
        updateItemQuantity,
        removeItem,
    } = useCart()

    const CheckEmptyFunc = () => {
        if (isEmpty) {
            return (
                <React.Fragment>
                    <div className="Busket__empty">
                        <Image src={BusketDronde} className={"Busket__empty-image"} />
                        <div className="Busket__empty-desc">
                            <Heading heading={"h3"}>Корзина пуста </Heading>
                            <Link to={"/catalog/"} className='Busket__empty-link'>Продолжить покупки</Link>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                {/* Product List */}
                <ul className="Busket__list">
                    {
                        items.map((item) => {
                            const { id, title, brand, price, quantity, img } = item
                            totalPrice += price * quantity
                            return (
                                <React.Fragment key={id}>
                                    <li className='Busket__item'>
                                        <div className="Busket__imageholder">
                                            <Image src={img} />
                                        </div>

                                        <div className="item__desc">
                                            <Heading heading={"h4"}> {title} </Heading>
                                            <Heading> Артикуль: {brand} </Heading>
                                        </div>

                                        <div className="item__wrap">
                                            <div className="counter">
                                                <button className='counter__button button-decreament' onClick={() => updateItemQuantity(id, quantity - 1)} >-</button>
                                                <span className="count">
                                                    {
                                                        quantity
                                                    }
                                                </span>
                                                <button className='counter__button button-increament' onClick={() => updateItemQuantity(id, quantity + 1)}>+</button>
                                            </div>

                                            <p className="price">$ {NumberFormatterFunc(price * quantity)} </p>

                                            <button className="remove-icon" onClick={() => removeItem(id)} >
                                                <Image src={Delete} alt={"error"} />
                                            </button>
                                        </div>
                                    </li>
                                </React.Fragment>
                            )
                        })
                    }
                </ul>
                {/* // Product List */}

                <div className="Busket__bottom">
                    <Link to={"/catalog"} className='Busket__link' >
                        Продолжить покупки
                    </Link>

                    <div className="Busket__bottom-wrap">
                        <div className="total-price">
                            <span>Всего:</span> <b className='price'>${NumberFormatterFunc(totalPrice)}</b>
                        </div>

                        <button className='Busket__button'>
                            Оформить заказ
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={`Busket ${busketStatus ? "" : "hidden"} `}>
            <Container>
                <div className="Busket__wrap">
                    <div className="Busket__content">
                        {/* Top */}
                        <div className="Busket__top">
                            <Heading heading={"h3"}>Корзина</Heading>

                            <button className='close' onClick={() => BusketFunc()} >
                                <Image src={Close} alt={"error"} />
                            </button>
                        </div>
                        {/* // Top */}

                        {
                            CheckEmptyFunc()
                        }

                    </div>
                </div>
            </Container>
        </div>
    )
}
