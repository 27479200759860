import React from 'react'
import "./slider.scss"
import Pagination from './pagination'

export default function Slider({ className = "", children, array = [], classNamePagination = "" }) {
  return (
    <React.Fragment>
      <div className={`Slider ${className}`} >
        {
          children
        }
      </div>

      <Pagination array={array} className={classNamePagination} />
    </React.Fragment>
  )
}
