import React from 'react'
import Image from '../image'
import Url from '../link'

export default function Social({ href, classes, src, alt = "error" }) {

  return (
    <div className={classes}>
      <Url href={href}>
        <Image src={src} alt={alt} />
      </Url>
    </div>
  )
}
