import React, { useContext, useEffect, useState } from 'react'
// Import
import "./catalog.scss"
import { Context } from '../../Context'

// Components
import Header from '../../components/header'
import Heading from '../../components/heading'
import Image from '../../components/image'
import Params from "../../components/params"
import Container from '../../components/container'
import SectionTitle from '../../components/section-title'
import Card from '../../components/card'

// Images
import Background from "../../images/catalog/bg-image.jpeg"
import Drone from "../../images/icons/drone_flying-2-icon.svg"
import Time from "../../images/icons/card/time-icon.svg"
import Fly from "../../images/icons/card/fly-icon.svg"
import RTK from "../../images/icons/catalog/RTK-icon.svg"
import Width from "../../images/icons/catalog/width-icon.svg"
import Fuel from "../../images/icons/catalog/fuel-icon.svg"
import IP67 from "../../images/icons/catalog/IP67-icon.svg"
import Drone2 from "../../images/icons/card/dron-icon.svg"
import Drone3 from "../../images/icons/card/drone-3-icon.svg"
import Drone4 from "../../images/icons/card/drone-4-icon.svg"
import Drone5 from "../../images/icons/card/drone-5-icon.svg"
import Motorcycle from "../../images/catalog/icon/motorcycle-icon.svg"
import Camera from "../../images/catalog/icon/camera-icon.svg"
import Mylcamera from "../../images/catalog/icon/mylcamera-icon.svg"
import Thermal from "../../images/catalog/icon/thermal-icon.svg"
import Setting from "../../images/catalog/icon/settings-icon.svg"
import Adapter from "../../images/catalog/icon/adapter-icon.svg"
import Battery from "../../images/catalog/icon/battery-icon.svg"
import Lan from "../../images/catalog/icon/lan-icon.svg"
import Generator from "../../images/catalog/icon/generator-icon.svg"
import Equalizer from "../../images/catalog/icon/equalizer-icon.svg"
import Settingsdron from "../../images/catalog/icon/settingsdron-icon.svg"
import Map from "../../images/catalog/icon/map-icon.svg"
import Coding from "../../images/catalog/icon/coding-icon.svg"
import axios from 'axios'

const AdvantagesArrray = [
    {
        id: 0,
        text: "Время полета 15 минут",
        icon: Time
    },

    {
        id: 1,
        text: "Скорость полета ",
        icon: Fly
    },

    {
        id: 2,
        text: "Автономная работа",
        icon: RTK
    },

    {
        id: 3,
        text: "Ширина захвата 8 м",
        icon: Width
    },

    {
        id: 4,
        text: "Емкость бака",
        icon: Fuel
    },

    {
        id: 5,
        text: "Водонепроницаемость",
        icon: IP67
    },
]

let ArrowDownDefault = <svg className='arrow-icon' width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1671 5.13452L7.99947 9.91646L2.83187 5.13452C2.73954 5.04891 2.6156 5.00098 2.48653 5.00098C2.35747 5.00098 2.23353 5.04891 2.1412 5.13452C2.0965 5.1761 2.06098 5.22573 2.03673 5.28051C2.01249 5.33528 2 5.3941 2 5.45351C2 5.51293 2.01249 5.57174 2.03673 5.62652C2.06098 5.68129 2.0965 5.73092 2.1412 5.7725L7.63863 10.8607C7.73517 10.95 7.86465 11 7.99947 11C8.13429 11 8.26377 10.95 8.36031 10.8607L13.8577 5.77349C13.9028 5.73187 13.9385 5.68212 13.963 5.62716C13.9874 5.57221 14 5.51316 14 5.45351C14 5.39386 13.9874 5.33481 13.963 5.27986C13.9385 5.2249 13.9028 5.17515 13.8577 5.13354C13.7654 5.04793 13.6415 5 13.5124 5C13.3833 5 13.2594 5.04793 13.1671 5.13354V5.13452Z" fill="white" />
</svg>

let ArrowDownActive = <svg className='arrow-icon' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="IconArrow-down">
        <path id="Vector" d="M3.04116 13.8206L9.50066 7.44473L15.9602 13.8206C16.0756 13.9348 16.2305 13.9987 16.3918 13.9987C16.5532 13.9987 16.7081 13.9348 16.8235 13.8206C16.8794 13.7652 16.9238 13.699 16.9541 13.626C16.9844 13.553 17 13.4745 17 13.3953C17 13.3161 16.9844 13.2377 16.9541 13.1646C16.9238 13.0916 16.8794 13.0254 16.8235 12.97L9.95171 6.18577C9.83104 6.06666 9.66918 6 9.50066 6C9.33214 6 9.17029 6.06666 9.04961 6.18577L2.17782 12.9687C2.12155 13.0242 2.07682 13.0905 2.04628 13.1638C2.01573 13.2371 2 13.3158 2 13.3953C2 13.4749 2.01573 13.5536 2.04628 13.6269C2.07682 13.7001 2.12155 13.7665 2.17782 13.8219C2.29323 13.9361 2.44816 14 2.60949 14C2.77082 14 2.92575 13.9361 3.04116 13.8219V13.8206Z" fill="#02E602" />
    </g>
</svg>

export default function Catalog() {
    const { productsArray, URLData } = useContext(Context)
    const [advantages] = useState(AdvantagesArrray)
    const [Products, setProducts] = useState(productsArray)
    const [catalogList, setCatalogList] = useState(false)

    const CatalogFunc = () => {
        setCatalogList((catalogList) => !catalogList)
    }

    useEffect(() => {
        axios.get(`${URLData}/products`).then(response => {
            const data = response.data;
            setProducts(data)
        })
    }, [])

    return (
        <React.Fragment>
            <Header title={"Дрон опрыскиватель AGR A22"} bg={Background} classes={"Catalog"}>
                <div className="Catalog__drone">
                    <Image src={Drone} alt={"error"} />
                </div>

                <div className="Catalog__advantage">
                    {
                        advantages.map((advantage) => {
                            const { id, icon, text } = advantage
                            return (
                                <div className="advantage__item" key={id}>
                                    <Image className={"advantage__icon"} src={icon} />
                                    <p className='advantage__text'> {text} </p>
                                </div>
                            )
                        })
                    }
                </div>
            </Header>

            <Params className="Catalog--params" />

            <main className="inner Catalog--inner">
                <section className='Catalog-products'>
                    <Container>
                        <SectionTitle className='Catalog-products__titles' title={"Наши предложения"} subtitle={"Каталог продукции"} />

                        <div className="Catalog-products__row">

                            <aside className='Catalog-products__side'>
                                <div className={`side__title ${catalogList ? "active" : ""}`} onClick={() => CatalogFunc()}>
                                    <Heading heading={"h4"}>
                                        Категории
                                    </Heading>
                                    {catalogList ? ArrowDownActive : ArrowDownDefault}
                                </div>

                                <div className={`side__wrap ${catalogList ? "show" : ""}`}>
                                    {/* List */}
                                    <ol className='side__list'>
                                        <li className='side__item'>
                                            <div className="side__item-wrap">
                                                <Image className={"side__icon"} src={Drone3} />

                                                <p className='side__text'> БПЛА Самолетного типа </p>
                                            </div>
                                        </li>

                                        <li className='side__item minuse'>
                                            <div className="side__item-wrap">
                                                <Image className={"side__icon"} src={Drone5} />

                                                <p className='side__text'> БПЛА мультироторного типа </p>
                                            </div>

                                            <ul className="side__nested-list">
                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Drone2} />

                                                    <p className='side__text'> Дроны опрыскиватели </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Drone4} />

                                                    <p className='side__text'> Дроны для картографии </p>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className='side__item minuse'>
                                            <div className="side__item-wrap">
                                                <Image className={"side__icon"} src={Motorcycle} />

                                                <p className='side__text'> Полезные нагрузки </p>
                                            </div>

                                            <ul className="side__nested-list">
                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Camera} />

                                                    <p className='side__text'> Фотокамеры </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Mylcamera} />

                                                    <p className='side__text'> Мультиспектральные камеры </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Thermal} />

                                                    <p className='side__text'> Гиростабилизированные видеокамеры и тепловизоры </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Setting} />

                                                    <p className='side__text'> Cпециализированные модули </p>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className='side__item minuse'>
                                            <div className="side__item-wrap">
                                                <Image className={"side__icon"} src={Adapter} />

                                                <p className='side__text'>Комплектующие </p>
                                            </div>

                                            <ul className="side__nested-list">
                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Battery} />

                                                    <p className='side__text'> Батареи</p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Lan} />

                                                    <p className='side__text'> Зарядные устройства </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Generator} />

                                                    <p className='side__text'> Генераторы </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Equalizer} />

                                                    <p className='side__text'> Пульты управления </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Map} />

                                                    <p className='side__text'> Высокоточная навигация </p>
                                                </li>

                                                <li className="nested-list__item">
                                                    <Image className={"side__icon"} src={Settingsdron} />

                                                    <p className='side__text'> Привязные системы для коптеров </p>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className='side__item'>
                                            <div className="side__item-wrap">
                                                <Image className={"side__icon"} src={Coding} />

                                                <p className='side__text'>Программное обеспечение </p>
                                            </div>
                                        </li>
                                    </ol>
                                    {/* // List */}

                                    <form className='Catalog__filter' onSubmit={(e) => e.preventDefault()}>

                                        <Heading heading={"h4"}>
                                            Производители
                                        </Heading>

                                        <label className='label'>

                                            <select className='Catalog__select'>
                                                <option value="all">Все</option>
                                                <option value="all">Все</option>
                                                <option value="all">Все</option>

                                            </select>

                                            <div className="icon"></div>
                                        </label>

                                        <Heading heading={"h4"}>
                                            Цена
                                        </Heading>

                                        <label className='label label--wrap'>
                                            <input type="number" className='input__number' placeholder='От' />

                                            <input type="number" className='input__number' placeholder='До' />
                                        </label>
                                    </form>
                                </div>
                            </aside>

                            <div className="product__cards Catalog--product__cards">
                                {
                                    Products.map((product) => {
                                        const { cover, title, brand, model, price, id } = product
                                        return (
                                            <Card path={`/products/${id}`} brand={brand} model={model} price={price} title={title} key={id} img={process.env.PUBLIC_URL + cover} card={"product"} obj={product}></Card>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </Container>
                </section>
            </main>
        </React.Fragment>
    )
}
