import React from 'react'
import "./section-title.scss"
import Heading from "../heading"

export default function SectionTitle({ subtitle, title, className = "" }) {
  
  return (
    <div className={`section-titles ${className}`} >
      <Heading heading={"h2"}>
        {title}
      </Heading>
      <Heading classes={"subtitle"}>
        {subtitle}
      </Heading>
    </div>
  )
}