import React, { useContext, useEffect, useState } from 'react'
import Card from '../components/card'
import Container from '../components/container'
import Header from '../components/header'
import SectionTitle from '../components/section-title'
import Image from "../components/image"
import { Link } from 'react-router-dom'
import Slider from "../components/slider"
import SectionDesc from '../components/section-desc'
import { Context } from '../Context'
import axios from 'axios'

const advantageCards = [
  {
    id: 1,
    img: "/people-icon.svg",
    title: "Квалифицированные сотрудники",
    body: `Все наши сотрудники имеют многолетний опыт работы в сфере беспилотной авиации. Нами было реализовано множество проектов различной тематики и самой различной сложности.`,
    type: "card"
  },

  {
    id: 2,
    img: "/tech-icon.svg",
    title: "Высокотехнологичное оборудование",
    body: `Наша компания использует современное оборудование, которое обеспечивает высокое качество в выполнении поставленных задач.`,
    type: "card"
  },

  {
    id: 3,
    img: "/savings-icon.svg",
    title: "Экономия",
    body: `Использование беспилотных технологий экономит вам много времени и денег. За счет отсутствия технологической колеи сохраняется до 6% урожая. Снижение расхода препаратов до 30%`,
    type: "card"
  },

  {
    id: 4,
    img: "/services-icon.svg",
    title: "Оперативность",
    body: `За рабочую смену наши специалисты способны защитить более 1000 гектар ваших полей, кустарников и деревьев.`,
    type: "card"
  },

  {
    id: 5,
    img: "/drone-icon.svg",
    title: "Собственное производство",
    body: `Мы разрабатываем и производим беспилотные летательные аппараты, поэтому знаем все тонкости их работы и можем настроить их под конкретные задачи и потребности клиентов.`,
    type: "card"
  },

  {
    id: 6,
    img: "/own-service-icon.svg",
    title: "Свой сервис",
    body: `Любой наш аппарат можно сдать на плановое техническое обслуживание или же в ремонт в наш собственный сервис, который гарантирует качество и оперативность выполнения работ.`,
    type: "card"
  },

]

export default function Home() {

  const { productsArray, URLData } = useContext(Context)

  const [NewsData, setNewsData] = useState([])
  const [Products, setProducts] = useState(productsArray)
  const [advantage] = useState(advantageCards)

  useEffect(() => {
    axios.get(`${URLData}/news`).then(response => {
      const data = response.data;
      setNewsData(() => data.sort((a, b) => b.date - a.date).slice(0, 3))
    })

    axios.get(`${URLData}/products`).then(response => {
      const data = response.data;
      setProducts(data.sort((a, b) => b - a).slice(0, 3))
    })
  }, [])

  return (
    <div>
      <Header classes='Home' title={"AgroScout - беспилотные технологии для сельского хозяйства и промышлености"} >
      </Header>
      <main className="inner">
        <section className='advantage'>
          <Container>
            <SectionTitle className='advantage__titles' title={"Почему нам дверяют?"} subtitle={"Наши приемущества "} />

            <Slider className='advantage__cards' array={advantage} classNamePagination='advantage--pagination'>

              {
                advantage.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Card card={item.type} img={process.env.PUBLIC_URL + "/images/icons/card" + item.img} title={item.title} body={item.body} />
                    </React.Fragment>
                  )
                })
              }

            </Slider>

          </Container>
        </section>
        <section className='product'>
          <Container className='Container-left product-container'>

            <SectionTitle className="product__titles" title={"Наши предложения"} subtitle={"Наша продукция"} />

            <Slider className='product__cards'>
              {
                Products.map((product) => {
                  const { cover, title, brand, model, price, id } = product
                  return (
                    <Card path={`/products/${title}`} brand={brand} model={model} price={price} title={title} key={id} img={process.env.PUBLIC_URL + cover} card={"product"} obj={product} />
                  )
                })
              }
            </Slider>
          </Container>
        </section>
        <section className='service'>
          <Container>
            <div className="service__content">
              <h2 className="title-2 service__title">Оказываем услуги!</h2>
              <p className="service__text">
                Принимаем заявки по биологической и химической защите растений дронами, картографии и мониторингу.
              </p>
              <Link to={"/contacts/"} className='service__button'>Оставить заявку</Link>
            </div>
          </Container>
        </section>
        <section className="about-company">
          <Container>
            <SectionTitle title={"Кто мы такие"} subtitle={"О компании"} />

            <SectionDesc className='about-company__content'>
              <span className='text__tablet'>
                Компания STS.center применяет высокотехнологичные агродроны и БПЛА, которые модернизированы и собраны нашими инженерами для сельского и лесного хозяйства, а также для промышленности. В СТС Центр работают опытные агрономы, энтомологи, пилоты, менеджеры и инженеры. Сегодня мы имеем один из самых современных парков дронов в России, что позволяет нам выполнять работы широкого спектра от простой аэрофотосъемки до лидарного сканирования местности и тепловизионного контроля. Внедрение беспилотных технологий в вашем бизнесе поможет сэкономить время и деньги, а также позволит получить большое преимущество перед вашими конкурентами.
              </span>

              <span className='text__mobile'>
                Компания STS.center применяет высокотехнологичные агродроны и БПЛА, которые модернизированы и собраны нашими инженерами для сельского и лесного хозяйства, а также для промышленности.
              </span>
            </SectionDesc>
          </Container>
        </section>
        <section className='work'>
          <Container className='Container-left'>
            <div className="work__titles">
              <SectionTitle title={"Где мы работаем"} subtitle={"Регионы присутствия"} />
            </div>
            <div className="work__row">
              <div className="work__lists">
                <ol className="work__list">
                  <li>1. Московская область</li>
                  <li>2. Ростовская область</li>
                  <li>3. Краснодарский край</li>
                  <li>4. Ставропольский край</li>
                  <li>5. Северная Осетия</li>
                  <li>6. Кабардино-Балкария</li>
                  <li>7. Пензеская область</li>
                  <li>8. Белгородская область</li>
                  <li>9. Саратовская область</li>
                  <li>10. Воронежская область</li>
                  <li>11. Волгоградская область</li>
                  <li>12. Астраханская область</li>

                </ol>
                <ol className="work__list">
                  <li>13. Самарская область</li>
                  <li>14. Липецкая область</li>
                  <li>15. Орловская область</li>
                  <li>16. Курская область</li>
                  <li>17. Ульяновская область</li>
                  <li>18. Карачаево-Черкессия</li>
                  <li>19. Брянская область</li>
                  <li>20. Республика Татарстан</li>
                  <li>21. Тамбовская область</li>
                  <li>22. Тульская область</li>
                  <li>23. Калужская область</li>
                  <li>24. Республика Крым</li>
                </ol>
              </div>
              <div className="work__imageholder">
                <Image src={process.env.PUBLIC_URL + "/images/other/map.svg"} />
              </div>
            </div>
          </Container>
        </section>
        <section className='clients'>
          <Container>
            <SectionTitle className='clients__title' title={"Наши клиенты "} subtitle={"Партнеры"} />
            <div className="clients__icons">
              <div className="client__icons-row">
                <div className="client__icon">
                  <Image src={process.env.PUBLIC_URL + "/images/clients/client-logo-bionovatic-icon.svg"} />
                </div>
                <div className="client__icon">
                  <Image src={process.env.PUBLIC_URL + "/images/clients/client-logo-bayer-icon.svg"} />
                </div>
                <div className="client__icon">
                  <Image src={process.env.PUBLIC_URL + "/images/clients/client-logo-corteva-icon.svg"} />
                </div>
                <div className="client__icon">
                  <Image src={process.env.PUBLIC_URL + "/images/clients/client-logo-kws-icon.svg"} />
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section className='home-news'>
          <Container className='home-news__container'>
            <SectionTitle title={"Что у нас нового"} subtitle={"Новости"} />

            <Slider className='home-news__row' array={NewsData} >
              {
                NewsData.map((newsItem, index) => {

                  const { image, title, description } = newsItem

                  return (
                    <Card key={index} card={"news-card"} img={process.env.PUBLIC_URL + image} title={title} body={description} />
                  )
                })
              }
            </Slider>

          </Container>
        </section>
      </main>
    </div>
  )
}
