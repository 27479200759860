import Product1 from "./images/products/product-1.png"
import Product2 from "./images/products/product-2.png"
import Product3 from "./images/products/product-3.png"
import Product4 from "./images/products/often_bought/1.png"
import Product5 from "./images/products/often_bought/2.png"
import Product7 from "./images/products/product-7.png"

export let productsArray = [
  {
    id: 1,
    title: "Агродрон AGR A22",
    brand: "AGR",
    model: "101-0101",
    price: 15001.27,
    quantity: 1,
    img: Product1,
    category: "product"
  },
  {
    id: 2,
    title: "Дрон- Картограф STS M8",
    brand: "STS",
    model: "101-11",
    price: 18206.30,
    quantity: 1,
    img: Product2,
    category: "product"
  },
  {
    id: 3,
    title: "Дрон - Картограф STS M1550",
    brand: "STS",
    model: "100-10",
    price: 13802.86,
    quantity: 1,
    img: Product3,
    category: "product"
  },
  {
    id: 4,
    title: "Камера Parrot Sequoia2",
    brand: "STS",
    model: "100-10",
    price: 5655.80,
    quantity: 1,
    img: Product4,
    category: "often bought"
  },
  {
    id: 5,
    title: "Контроллер Pixhawk 2.1",
    brand: "STS",
    model: "200-01",
    price: 433.61,
    quantity: 1,
    img: Product5,
    category: "often bought"
  },
  {
    id: 6,
    title: "Картограф STS M1500",
    brand: "STS",
    model: "100-10",
    price: 13802.86,
    quantity: 1,
    img: Product7,
    category: "product"
  }
]